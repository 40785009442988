import loadable, { DefaultComponent } from "@loadable/component";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { ADMIN_PERMISSION } from "src/constants";
import { AdminRoleAuthorityTab } from "src/constants/adminMember";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import CategoryDetail from "src/pages/category-detail";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import MerchantLevelPage from "src/pages/merchant-level";
import { ReduxStore } from "src/types/globalStore";
import { PrivateRoute } from "./components/PrivateRoute";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(
  loadFn: (props: {}) => Promise<DefaultComponent<{}>>
) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/NotFound"));
const LoginPage = loadableWFallback(() => import("src/pages/login"));
const Merchant = loadableWFallback(() => import("src/pages/merchant"));
const Consumer = loadableWFallback(() => import("src/pages/consumer"));
const Store = loadableWFallback(() => import("src/pages/store"));
const StoreDetail = loadableWFallback(() => import("src/pages/store-detail"));
const AdminUpdateStoreDetail = loadableWFallback(
  () => import("src/pages/update-store-detail/update-store-detail")
);
const TriMember = loadableWFallback(() => import("src/pages/admin-member"));
const Role = loadableWFallback(() => import("src/pages/permission-management"));
const Notification = loadableWFallback(() => import("src/pages/notification"));
const CategoryAndAttribute = loadableWFallback(
  () => import("src/pages/category-and-attribute")
);
const ProductManagement = loadableWFallback(
  () => import("src/pages/productManagement")
);

const MerchantDetailPage = loadableWFallback(
  () => import("src/pages/merchant-detail")
);
const OnboardDetailPage = loadableWFallback(
  () => import("src/pages/onboard-request-detail")
);
const UpdateInformationDetailPage = loadableWFallback(
  () => import("src/pages/update-information-request-detail")
);
const DashboardConfigPage = loadableWFallback(
  () => import("src/pages/dashboard-config")
);
const ConfigMenuPage = loadableWFallback(
  () => import("src/pages/dashboard-config/select-category")
);
const ConfigFeaturedRestaurants = loadableWFallback(
  () => import("src/pages/dashboard-config/select-store")
);
const Reconciliation = loadableWFallback(
  () => import("src/pages/reconciliation/index")
);
const ReconciliationMerchantByDate = loadableWFallback(
  () => import("src/pages/reconciliation/MerchantSettlementReports")
);
const ReconciliationStoreByDate = loadableWFallback(
  () => import("src/pages/reconciliation/StoreSettlementReport")
);
const ReconciliationOrderByStoreByDate = loadableWFallback(
  () => import("src/pages/reconciliation/StoreSettlementReportDetail")
);
const ReconciliationDetail = loadableWFallback(
  () => import("src/pages/reconciliation/detail")
);
const ConfigTopSellingDishesPage = loadableWFallback(
  () => import("src/pages/dashboard-config/select-product")
);
const ParametersPage = loadableWFallback(() => import("src/pages/parameters"));
const EmailTemplatePage = loadableWFallback(
  () => import("src/pages/email-template")
);
const EmailTemplateDetailPage = loadableWFallback(
  () => import("src/pages/email-template-detail")
);
const Report = loadableWFallback(() => import("src/pages/report"));
const ReportDetail = loadableWFallback(() => import("src/pages/report-detail"));
const ReportMerchantDetail = loadableWFallback(
  () => import("src/pages/report-merchant-detail")
);
const ForgotPassword = loadableWFallback(
  () => import("src/pages/forgot-password")
);
const ForgotSuccess = loadableWFallback(
  () => import("src/pages/forgot-success")
);
const ResetPassword = loadableWFallback(
  () => import("src/pages/reset-password")
);
const LpCashOut = loadableWFallback(() => import("src/pages/lp-management"));
const MemberOnboard = loadableWFallback(
  () => import("src/pages/member-onboard")
);

const LogisticFee = loadableWFallback(() => import("src/pages/logistic-fee"));

const CreateMerchant = loadableWFallback(
  () => import("src/pages/create-merchant")
);

const CreateProduct = loadableWFallback(
  () => import("src/pages/create-product")
);

const FeeManagement = loadableWFallback(
  () => import("src/pages/fee-management")
);

const Refund = loadableWFallback(() => import("src/pages/refund"));

const RefundDetail = loadableWFallback(
  () => import("src/pages/refund/refundDetail")
);

const MerchantFeeDetail = loadableWFallback(
  () =>
    import("src/pages/merchant-level/DetailMerchantLevel/DetailMerchantLevel")
);

const ListOrder = loadableWFallback(() => import("src/pages/list-order"));

const Home = loadableWFallback(() => import("src/pages/home"));

const ReportUserMonthly = loadableWFallback(
  () => import("src/pages/report-users/report-users-monthly")
);

const ReportUserDaily = loadableWFallback(
  () => import("src/pages/report-users/report-users-daily")
);

const AppVersion = loadableWFallback(() => import("src/pages/app-version"));

const Routes: React.FC = () => {
  const listAdminPermission = useSelector(
    (state: ReduxStore) => state.user.adminPermission
  );

  const currentAdminPermission = useMemo(() => {
    if (!listAdminPermission) return;

    const listPermission = Object.keys(listAdminPermission).filter((item) => {
      return (
        listAdminPermission[item as keyof typeof listAdminPermission] ===
          ADMIN_PERMISSION.Edit ||
        listAdminPermission[item as keyof typeof listAdminPermission] ===
          ADMIN_PERMISSION.View
      );
    });

    return listPermission;
  }, [listAdminPermission]);

  const BasicRoutes = [
    <Route
      exact
      path="/"
      render={() => (
        <Route exact path={PATHS.default()} render={() => <Home />} />
      )}
    />,
    <Route
      exact
      path={PATHS.login()}
      render={() =>
        hasStorageJwtToken() ? (
          <Redirect to={PATHS.merchant()} />
        ) : (
          <LoginPage />
        )
      }
    />,
    <Route
      exact
      path={PATHS.forgotPassword()}
      render={() =>
        hasStorageJwtToken() ? (
          <Redirect to={PATHS.merchant()} />
        ) : (
          <ForgotPassword />
        )
      }
    />,
    <Route
      exact
      path={PATHS.forgotSuccess()}
      render={() =>
        hasStorageJwtToken() ? (
          <Redirect to={PATHS.merchant()} />
        ) : (
          <ForgotSuccess />
        )
      }
    />,
    <Route
      exact
      path={PATHS.resetPassword()}
      render={() =>
        hasStorageJwtToken() ? (
          <Redirect to={PATHS.merchant()} />
        ) : (
          <ResetPassword />
        )
      }
    />,
    <Route
      exact
      path={PATHS.memberOnboard()}
      render={() => <MemberOnboard />}
    />,
  ];

  const MerchantAndStoreManagement = [
    <PrivateRoute exact path={PATHS.merchant()} component={Merchant} />,
    <PrivateRoute exact path={PATHS.consumer()} component={Consumer} />,
    <PrivateRoute exact path={PATHS.store()} component={Store} />,
    <PrivateRoute exact path={PATHS.storeDetail()} component={StoreDetail} />,
    <PrivateRoute
      exact
      path={PATHS.storeOnboardDetail()}
      component={StoreDetail}
    />,
    <PrivateRoute
      exact
      path={PATHS.storeUpdateDetail()}
      component={StoreDetail}
    />,
    <PrivateRoute
      exact
      component={AdminUpdateStoreDetail}
      path={PATHS.adminEditUpdateStoreDetail()}
    />,
    <PrivateRoute
      exact
      component={AdminUpdateStoreDetail}
      path={PATHS.adminEditOnboardStoreDetail()}
    />,
    <PrivateRoute
      exact
      component={AdminUpdateStoreDetail}
      path={PATHS.createStore()}
    />,
    <PrivateRoute
      exact
      component={AdminUpdateStoreDetail}
      path={PATHS.editStore()}
    />,
    <PrivateRoute
      exact
      path={PATHS.merchantDetail()}
      component={MerchantDetailPage}
    />,
    <PrivateRoute
      exact
      path={PATHS.onboardDetail()}
      component={OnboardDetailPage}
    />,
    <PrivateRoute
      exact
      path={PATHS.updateInformationDetail()}
      component={UpdateInformationDetailPage}
    />,
    <PrivateRoute
      exact
      path={PATHS.productManagement()}
      component={ProductManagement}
    />,
    <PrivateRoute
      exact
      path={PATHS.createProduct()}
      component={CreateProduct}
    />,
    <PrivateRoute exact path={PATHS.editProduct()} component={CreateProduct} />,
    <PrivateRoute
      exact
      path={PATHS.merchantLevel()}
      component={MerchantLevelPage}
    />,
    <PrivateRoute
      exact
      path={PATHS.merchantFeeDetail()}
      component={MerchantFeeDetail}
    />,
    <PrivateRoute
      exact
      path={PATHS.createMerchant()}
      component={CreateMerchant}
    />,
    <PrivateRoute exact path={PATHS.lpCashOut()} component={LpCashOut} />,
  ];

  const PlatformManagement = [
    <PrivateRoute
      exact
      path={PATHS.dashboardConfig()}
      component={DashboardConfigPage}
    />,
    <PrivateRoute exact path={PATHS.configMenu()} component={ConfigMenuPage} />,
    <PrivateRoute
      exact
      path={PATHS.configTopSellingDishes()}
      component={ConfigTopSellingDishesPage}
    />,
    <PrivateRoute
      exact
      path={PATHS.configFeaturedRestaurants()}
      component={ConfigFeaturedRestaurants}
    />,
    <PrivateRoute
      exact
      path={PATHS.categoryAndAttribute()}
      component={CategoryAndAttribute}
    />,
    <PrivateRoute
      exact
      path={PATHS.categoryDetail()}
      component={CategoryDetail}
    />,
    <PrivateRoute exact path={PATHS.report()} component={Report} />,
    <PrivateRoute
      exact
      path={PATHS.reportUserMonthly()}
      component={ReportUserMonthly}
    />,
    <PrivateRoute
      exact
      path={PATHS.reportUserDaily()}
      component={ReportUserDaily}
    />,
    <PrivateRoute exact path={PATHS.reportDetail()} component={ReportDetail} />,
    <PrivateRoute
      exact
      path={PATHS.reportMerchantDetail()}
      component={ReportMerchantDetail}
    />,
    <PrivateRoute exact path={PATHS.appVersion()} component={AppVersion} />,
  ];

  const SystemSetup = [
    <PrivateRoute exact path={PATHS.parameters()} component={ParametersPage} />,
    <PrivateRoute
      exact
      path={PATHS.platformFeeManagement()}
      component={FeeManagement}
    />,
  ];

  const CustomerSupport = [
    <PrivateRoute exact path={PATHS.listOrders()} component={ListOrder} />,
    <PrivateRoute exact path={PATHS.reportDetail()} component={ReportDetail} />,
    <PrivateRoute exact path={PATHS.refund()} component={Refund} />,
    <PrivateRoute exact path={PATHS.refundDetail()} component={RefundDetail} />,
  ];

  const UserManagement = [
    <PrivateRoute exact path={PATHS.member()} component={TriMember} />,
    <PrivateRoute exact path={PATHS.role()} component={Role} />,
  ];

  const FinanceManagement = [
    <PrivateRoute
      exact
      path={PATHS.reconciliation()}
      component={Reconciliation}
    />,
    <PrivateRoute
      exact
      path={PATHS.reconciliationDetail()}
      component={ReconciliationDetail}
    />,
    <PrivateRoute
      exact
      path={PATHS.reconciliationMerchantByDate()}
      component={ReconciliationMerchantByDate}
    />,
    <PrivateRoute
      exact
      path={PATHS.reconciliationStoreByDate()}
      component={ReconciliationStoreByDate}
    />,
    <PrivateRoute
      exact
      path={PATHS.reconciliationOrderByDate()}
      component={ReconciliationOrderByStoreByDate}
    />,
  ];

  const OperatorManagement = [
    <PrivateRoute exact path={PATHS.listOrders()} component={ListOrder} />,
    <PrivateRoute exact path={PATHS.reportDetail()} component={ReportDetail} />,
    <PrivateRoute exact path={PATHS.refund()} component={Refund} />,
    <PrivateRoute exact path={PATHS.refundDetail()} component={RefundDetail} />,
  ];
  const getRoutes = useMemo(() => {
    if (!currentAdminPermission)
      return [
        ...MerchantAndStoreManagement,
        ...PlatformManagement,
        ...SystemSetup,
        ...CustomerSupport,
        ...BasicRoutes,
      ];

    const listRoutes = [...BasicRoutes];
    currentAdminPermission.forEach((permission: string) => {
      switch (permission) {
        case AdminRoleAuthorityTab.USER_MANAGEMENT:
          listRoutes.push(...MerchantAndStoreManagement);
          break;
        case AdminRoleAuthorityTab.DASHBOARD_CONFIG:
          listRoutes.push(...PlatformManagement);
          break;
        case AdminRoleAuthorityTab.PARAMETERS:
          listRoutes.push(...SystemSetup);
          break;
        case AdminRoleAuthorityTab.TRI_MEMBER:
          listRoutes.push(...UserManagement);
          break;
        case AdminRoleAuthorityTab.CUSTOMER_SUPPORT:
          listRoutes.push(...CustomerSupport);
          break;
        case AdminRoleAuthorityTab.FINANCES:
          listRoutes.push(...FinanceManagement);
          break;
        case AdminRoleAuthorityTab.OPERATIONS:
          listRoutes.push(...OperatorManagement);
          break;
        default:
          break;
      }
    });

    return listRoutes;
  }, [currentAdminPermission]);

  return (
    <Switch>
      {getRoutes?.map((item, idx) => item)}

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
